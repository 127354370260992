import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { DateRange } from 'react-day-picker';
import { MultiSelectOption } from 'src/types/Forms/Forms';
import { PropertiesContext } from './PropertiesContext';
import { isArray, uniqBy } from 'lodash';
import { useUser } from './UserContext';
import { UserApi } from 'services/api';
import { GetPricingSettingByBuildingIdsData } from 'src/types/User/pricingSetting';

interface LostReasons {
  'Competition has better price': string[];
  'Location doesn’t work': string[];
  'Amenities are lacking': string[];
  'Bad resident historty/credit': string[];
  'Unit in poor condition': string[];
  'Change of plans': string[];
  Unresponsive: string[];
}

interface PortfolioData {
  Leads: string[];
  Showings: string[];
  'App. Requested': string[];
  Approved: string[];
  'Lease velocity': string[];
  'Available Units': string[];
  'Vacancy (%)': string[];
  'Budgeted Vacancy': string[];
  'Contacts Made': string[];
  'No shows': string[];
  '# of units': string[];
  'Renewal Rate (%)': string[];
  'Net Eff Rent per unit': string[];
  'Conversion rate': string[];
  'Lost reasons': LostReasons;
}

export interface DashboardContextType {
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
}

interface StateType {
  selectedProperties: MultiSelectOption[];
  propertyOptions: MultiSelectOption[];
  comparableProperties: string[];
  selectedUser: MultiSelectOption | undefined;
  dateRange: DateRange | undefined;
  filtersLocked: boolean;
  selectedPortfolio: string | undefined;
  portfolioOptions: PortfolioData[];
  selectedDashboard: string;
  bedrooms: MultiSelectOption[];
  selectedCities: MultiSelectOption[];
  allCities: MultiSelectOption[];
  currentCityOptions: MultiSelectOption[];
  selectedNeighbourhoods: MultiSelectOption[];
  allNeighbourhoods: MultiSelectOption[];
  currentNeighbourhoodOptions: MultiSelectOption[];
}

type ActionType =
  | { type: 'SET_SELECTED_PROPERTIES'; payload: MultiSelectOption[] }
  | { type: 'SET_PROPERTY_OPTIONS'; payload: MultiSelectOption[] }
  | { type: 'SET_COMPARABLE_PROPERTIES'; payload: string[] }
  | { type: 'SET_SELECTED_USER'; payload: MultiSelectOption | undefined }
  | { type: 'SET_DATE_RANGE'; payload: DateRange | undefined }
  | { type: 'SET_FILTERS_LOCKED'; payload: boolean }
  | { type: 'SET_PORTFOLIO'; payload: string | undefined }
  | { type: 'SET_PORTFOLIO_OPTIONS'; payload: [] }
  | { type: 'SET_SELECTED_DASHBOARD'; payload: string }
  | { type: 'SET_BEDROOMS'; payload: MultiSelectOption[] }
  | { type: 'SET_SELECTED_CITIES'; payload: MultiSelectOption[] }
  | { type: 'SET_ALL_CITIES'; payload: MultiSelectOption[] }
  | { type: 'SET_CURRENT_CITY_OPTIONS'; payload: MultiSelectOption[] }
  | { type: 'SET_SELECTED_NEIGHBOURHOODS'; payload: MultiSelectOption[] }
  | { type: 'SET_ALL_NEIGHBOURHOODS'; payload: MultiSelectOption[] }
  | { type: 'SET_CURRENT_NEIGHBOURHOOD_OPTIONS'; payload: MultiSelectOption[] };

const initialState: StateType = {
  selectedProperties: [],
  propertyOptions: [],
  comparableProperties: [],
  selectedUser: undefined,
  dateRange: undefined,
  filtersLocked: false,
  selectedPortfolio: '',
  portfolioOptions: [],
  selectedDashboard: 'overview',
  bedrooms: [],
  selectedCities: [],
  allCities: [],
  currentCityOptions: [],
  selectedNeighbourhoods: [],
  allNeighbourhoods: [],
  currentNeighbourhoodOptions: [],
};

const reducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case 'SET_SELECTED_PROPERTIES':
      return { ...state, selectedProperties: action.payload };
    case 'SET_PROPERTY_OPTIONS':
      return { ...state, propertyOptions: action.payload };
    case 'SET_COMPARABLE_PROPERTIES':
      return { ...state, comparableProperties: action.payload };
    case 'SET_SELECTED_USER':
      return { ...state, selectedUser: action.payload };
    case 'SET_DATE_RANGE':
      return { ...state, dateRange: action.payload };
    case 'SET_FILTERS_LOCKED':
      return { ...state, filtersLocked: action.payload };
    case 'SET_PORTFOLIO':
      return { ...state, selectedPortfolio: action.payload };
    case 'SET_PORTFOLIO_OPTIONS':
      return { ...state, portfolioOptions: action.payload };
    case 'SET_SELECTED_DASHBOARD':
      return { ...state, selectedDashboard: action.payload };
    case 'SET_BEDROOMS':
      return { ...state, bedrooms: action.payload };
    case 'SET_SELECTED_CITIES':
      return { ...state, selectedCities: action.payload };
    case 'SET_ALL_CITIES':
      return { ...state, allCities: action.payload };
    case 'SET_CURRENT_CITY_OPTIONS':
      return { ...state, currentCityOptions: action.payload };
    case 'SET_SELECTED_NEIGHBOURHOODS':
      return { ...state, selectedNeighbourhoods: action.payload };
    case 'SET_ALL_NEIGHBOURHOODS':
      return { ...state, allNeighbourhoods: action.payload };
    case 'SET_CURRENT_NEIGHBOURHOOD_OPTIONS':
      return { ...state, currentNeighbourhoodOptions: action.payload };
    default:
      return state;
  }
};

export const DashboardContext = createContext<DashboardContextType | null>(null);

export const DashboardContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user: currentUser } = useUser();
  const propertiesContext = useContext(PropertiesContext);

  // useEffect to map all building names to a MultiSelectOption
  // In the case of multiple entries with the same building name but different building IDs, the MultiSelectOption will have label: building.name and value: a concatenated csv string of all building ids
  useEffect(() => {
    const uniqueBuildingsMap = new Map();

    propertiesContext?.state?.data?.forEach(p => {
      if (p.building) {
        const existingEntry = uniqueBuildingsMap.get(p.building.name);

        if (existingEntry) {
          // If an entry with the same building name already exists, add the new building id to the set
          const updatedIds = new Set([...existingEntry.value.split(','), p.building.id]);
          uniqueBuildingsMap.set(p.building.name, {
            label: p.building.name || p.building.shortAddress,
            value: Array.from(updatedIds).join(','),
          });
        } else {
          // If no entry with the same building name exists, create a new one with a single ID
          uniqueBuildingsMap.set(p.building.name, {
            label: p.building.name || p.building.shortAddress,
            value: p.building.id.toString(),
          });
        }
      }
    });

    dispatch({
      type: 'SET_PROPERTY_OPTIONS',
      payload: Array.from(uniqueBuildingsMap.values()),
    });
  }, [propertiesContext?.state?.data]);

  // useEffect to set city options
  useEffect(() => {
    const city: MultiSelectOption[] = [];
    const buildingDict: Record<string, string[]> = {};
    //set city data for dropdown
    // Iterate over the unique properties, ensuring there are no duplicate 'building.id' values.
    uniqBy(propertiesContext?.state?.data, 'building.id')
      .filter(p => {
        return state.selectedProperties.length === 0
          ? state.propertyOptions.filter(property => property.value.includes(p.building?.id)).length > 0
          : state.selectedProperties.filter(property => property.value.includes(p.building?.id)).length > 0;
      })
      .forEach(p => {
        // If the building has a city, push a new MultiSelectOption into the 'city' array with
        // the building's city as both the label and the value.
        if (p.building?.city) {
          city.push({
            label: p.building.city,
            value: p.building.city,
          });
        }

        // If the building name already exists in the buildingDict, push the new building ID into
        // the array. Otherwise, create a new entry in the dictionary with the building name as the key
        // and initialize it with an array containing the current building ID.
        buildingDict[p.building.name]
          ? buildingDict[p.building.name].push(p.building.id)
          : (buildingDict[p.building.name] = [p.building.id]);
      });

    // Create a new array with unique city options based on their label, ensuring that no duplicate
    // city labels exist in the array.
    const newCityData = uniqBy(city, 'label');

    dispatch({
      type: 'SET_CURRENT_CITY_OPTIONS',
      payload: newCityData,
    });

    // If there is at least one unique city option, set the 'SET_SELECTED_CITIES' based on the currentUser.
    if (newCityData.length > 0) {
      dispatch({
        type: 'SET_SELECTED_CITIES',
        payload:
          currentUser?.id === 'a73cbbcb-3aa5-4d2b-a68c-ee5ff734961a'
            ? [{ label: 'Toronto', value: 'Toronto' }]
            : currentUser?.id === '6a532c61-7378-4f01-918e-56c8fde870d4'
            ? []
            : // Otherwise, set the selected cities to the first city in 'newCityData'.
            state.selectedProperties.length === 0
            ? [{ label: newCityData[0].label, value: newCityData[0].value }]
            : [],
      });
    }
  }, [propertiesContext?.state?.data, state.selectedProperties, state.propertyOptions, dispatch, currentUser]);

  useEffect(() => {
    if (!propertiesContext?.state?.data || !dispatch) {
      // If any of these are null or undefined, do nothing
      return;
    }

    const neighbourhood: MultiSelectOption[] = [];
    uniqBy(propertiesContext.state.data, 'building.id').forEach(property => {
      // If there are any selected cities, filter properties by whether they match one of the selected cities.
      if (state.allCities.length > 0) {
        if (state.allCities.find(city => city.value === property.building?.city)) {
          // If the property has a neighbourhood, add it to the neighbourhood array as a MultiSelectOption.
          if (property.building.neighbourhood) {
            neighbourhood.push({
              label: property.building.neighbourhood,
              value: property.building.neighbourhood,
            });
          }
        }
      } else {
        // If there are no selected cities, add all neighbourhoods to the neighbourhood array.
        if (property.building.neighbourhood) {
          neighbourhood.push({
            label: property.building.neighbourhood,
            value: property.building.neighbourhood,
          });
        }
      }
    });
    // Remove any duplicate neighbourhoods by their value (neighbourhood name).
    const newNeighborhoodData = uniqBy(neighbourhood, 'value');
    dispatch({
      type: 'SET_ALL_NEIGHBOURHOODS',
      payload: newNeighborhoodData,
    });
  }, [propertiesContext?.state?.data, state.selectedCities, state.allCities, dispatch]);

  // Re-filter city options and neighbourhood options according to selectedCities
  useEffect(() => {
    if (!propertiesContext?.state?.data || !dispatch) {
      // If any of these are null or undefined, do nothing
      return;
    }

    const neighbourhood: MultiSelectOption[] = [];
    uniqBy(propertiesContext.state.data, 'building.id').forEach(property => {
      // If there are any selected cities, filter properties by whether they match one of the selected cities.
      if (state.selectedCities.length > 0) {
        if (state.selectedCities.find(city => city.value === property.building?.city)) {
          // If the property has a neighbourhood, add it to the neighbourhood array as a MultiSelectOption.
          if (property.building.neighbourhood) {
            neighbourhood.push({
              label: property.building.neighbourhood,
              value: property.building.neighbourhood,
            });
          }
        }
      } else {
        // If there are no selected cities, add all neighbourhoods to the neighbourhood array.
        // if (property.building.neighbourhood) {
        //   neighbourhood.push({
        //     label: property.building.neighbourhood,
        //     value: property.building.neighbourhood,
        //   });
        // }
        if (state.currentCityOptions.find(city => city.value === property.building?.city)) {
          // If the property has a neighbourhood, add it to the neighbourhood array as a MultiSelectOption.
          if (property.building.neighbourhood) {
            neighbourhood.push({
              label: property.building.neighbourhood,
              value: property.building.neighbourhood,
            });
          }
        }
      }
    });
    // Remove any duplicate neighbourhoods by their value (neighbourhood name).
    const newNeighborhoodData = uniqBy(neighbourhood, 'value');
    dispatch({
      type: 'SET_CURRENT_NEIGHBOURHOOD_OPTIONS',
      payload: newNeighborhoodData,
    });
  }, [propertiesContext?.state?.data, state.selectedCities, state.currentCityOptions, dispatch]);

  // useEffect to set comparable properties
  useEffect(() => {
    const setComparableProperties = async () => {
      if (!currentUser) return;
      try {
        const concatenatedBuildingIds = state.selectedProperties.reduce((acc, curr) => {
          // Split the curr.value by commas to handle multiple UUIDs and ensure each one is wrapped in double quotes
          const quotedValues = curr.value
            .split(',')
            .map(id => `"${id}"`)
            .join(',');

          return acc ? `${acc},${quotedValues}` : quotedValues;
        }, '');

        const res = await UserApi.getPricingSettingByBuildingIds({
          buildingIds: `{${concatenatedBuildingIds}}`,
        });

        const data: GetPricingSettingByBuildingIdsData[] = res.data;

        const finalArr: string[] = [];
        data.forEach((item: GetPricingSettingByBuildingIdsData) => {
          if (item.comparableBuildings && isArray(item.comparableBuildings))
            item.comparableBuildings.forEach(bldng => finalArr.push(bldng.address));
        });
        const finalSet = Array.from(new Set(finalArr));

        dispatch({
          type: 'SET_COMPARABLE_PROPERTIES',
          payload: finalSet,
        });
      } catch (error) {
        console.log(error);
      }
    };

    setComparableProperties();
  }, [state.selectedProperties]);

  return <DashboardContext.Provider value={{ state, dispatch }}>{children}</DashboardContext.Provider>;
};
